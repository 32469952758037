import React, { useState, useEffect } from "react";
import "./styles.scss";
import Database from "../../_utils/Database";

export default function CookiesButton() {
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        setAccepted(Database.getCookieAccepted());
    }, []);

    const handleCookies = (e) => {
        if (e.target.id === 'accept') {
            Database.setCookieAccepted(true);
        } else {
            Database.removeCookieAccepted();
        }
        setAccepted(!accepted);
    }

    return (
        <div className="manage-cookies">
            <button
                id={!accepted ? "accept" : "reject"}
                className="btn btn-primary m-3"
                onClick={(e) => handleCookies(e)}
            >
                {!accepted ? 'Accept Cookies' : 'Reject Cookies'}
            </button>
        </div>
    )
}
